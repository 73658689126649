import * as React from "react";
import Layout from "../components/layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout>
      <Row className="mb-3">
        <Col>
          <StaticImage src="../images/img_about.jpg" alt="" placeholder="blurred" />
        </Col>
      </Row>

      <Row className="about">
        <Col>




        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
